<template>
  <div class="w-100 p-2 mt-2">
    <page-header>
      <i class="fas fa-list"></i>
      Withdraw requests list
    </page-header>
    <page-card>
      <template v-slot:header>
        {{ $t('page.filters') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row">
            <div class="col-4">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="id"
                  :label="$t('filters.label.id')"
                  :placeholder="$t('filters.placeholder.id')">
              </filter-input>
            </div>
            <div class="col-4">
              <filter-input
                  :filter-query="filterQuery"
                  @store="storeFilter"
                  :store-data="filters"
                  filter-operator="like"
                  filter-name="email"
                  :label="$t('filters.label.email')"
                  :placeholder="$t('filters.placeholder.email')">
              </filter-input>
            </div>
            <div class="col-4">
              <filter-select
                  @store="storeFilter"
                  :store-data="filters"
                  :filter-query="filterQuery"
                  :options="filterOptions.status"
                  :multiple="false"
                  options-label="text"
                  options-track-by="value"
                  filter-name="status"
                  :label="$t('filters.label.status')"
                  :placeholder="$t('filters.placeholder.status')">
              </filter-select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <b-button @click="resetFilters()" variant="secondary">{{ $t('filters.btn.clear') }}</b-button>
        <b-button @click="applyFilters()" variant="primary">{{ $t('filters.btn.apply') }}</b-button>
      </template>
    </page-card>

    <page-card>
      <template v-slot:header>
        {{ $t('page.list') }}
      </template>
      <template v-slot:content>
        <div class="sygni-grid">
          <div class="row mt-2">
            <div class="col-12">
              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

              <div class="table-responsive">
                <b-table striped show-empty
                         :empty-text="$t('table.no_results')"
                         :busy="isBusy"
                         hover
                         head-variant="dark"
                         outlined
                         :sort-by.sync="orderColumn"
                         :sort-desc.sync="orderDirection"
                         :no-local-sorting="true"
                         :items="items"
                         :fields="fields">
                  <template #cell(actions)="data">
                    <div class="table-action--cell">
                      <b-button variant="success" size="sm"
                                v-if="data.item.status==='requested'"
                                @click="accept(data.item)">
                        {{ $t('table.btn.accept') }}
                      </b-button>
                      <b-button variant="danger" size="sm"
                                v-if="data.item.status==='requested'"
                                @click="reject(data.item)">
                        {{ $t('table.btn.reject') }}
                      </b-button>
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('state.loading') }}</strong>
                    </div>
                  </template>

                </b-table>
              </div> <!-- #table-responsive-->

              <grid-pagination :total-count="totalCount"
                               :per-page="perPage"
                               :current-page="currentPage"
                               @load-items="paginationReload"></grid-pagination>

            </div>
          </div>
        </div>
      </template>
    </page-card>
  </div>
</template>

<script>

import FilterInput from "../../../components/grid/filters/FilterInput";
import FilterSelect from "../../../components/grid/filters/FilterSelect";
import GridPagination from "../../../components/grid/grid-pagination/GridPagination";
import PageHeader from "../../../components/page/PageHeader";
import PageCard from "../../../components/page/PageCard";
import {mapState} from "vuex";

export default {
  props: ['apiKey'],
  components: {
    PageCard,
    PageHeader,
    GridPagination,
    FilterInput,
    FilterSelect
  },
  data() {
    return {
      documentUrl: null,
      orderColumn: null,
      orderDirection: false,
      fields: [
        {key: 'createdAt', label: this.$t('table.header.createdAt'), formatter: (v) => {
            return (new Date(v)).toLocaleDateString() + ' ' + (new Date(v)).toLocaleTimeString();
          }
        },
        {key: 'address', label: this.$t('table.header.address')},
        {key: 'email', label: this.$t('table.header.email')},
        {key: 'firstName', label: this.$t('table.header.firstName')},
        {key: 'lastName', label: this.$t('table.header.lastName')},
        {key: 'requestedAmount', label: this.$t('table.header.requestedAmount'), class: ['text-right']},
        {key: 'feeAmount', label: this.$t('table.header.feeAmount'), class: ['text-right']},
        {key: 'status', label: this.$t('table.header.status'), class: ['text-center']},
        {key: 'actions', label: this.$t('table.header.actions')},
      ],
      selectOptions: {
        bulkActions: [
          {value: null, text: 'Bulk actions'}
        ],
      },
      filterQuery: [],
      filterOptions: {
        status: [
          {value:'requested', text: 'REQUESTED'},
          {value:'accepted', text: 'ACCEPTED'},
          {value:'rejected', text: 'REJECTED'},
        ],
      },
    };
  },
  mounted() {
    if (this.apiKey) {
      this.$store.commit('root/setApiKey', this.apiKey);
    }
    this.loadItems();
    if (!this.filters) {
      this.initFilters();
    }
    document.addEventListener('keydown', this.bindEnter);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.bindEnter);
  },
  computed: {
    ...mapState({
      perPage: (state) => state.withdrawStore.perPage,
      currentPage: (state) => state.withdrawStore.currentPage,
      items: (state) => state.withdrawStore.items,
      isBusy: (state) => state.withdrawStore.isBusy,
      totalCount: (state) => state.withdrawStore.totalCount
    }),
    filters: function () {
      return this.$store.getters["root/filter"]('kyc');
    },
  },
  methods: {
    storeFilter(data) {
      this.$store.commit('root/setFilter', {storeName: 'withdraw', ...data})
    },
    createSortQuery() {
      let sorting;
      if (this.orderColumn) {
        if (this.orderDirection === true) {
          sorting = encodeURIComponent('+' + this.orderColumn);
        } else {
          sorting = encodeURIComponent('-' + this.orderColumn);
        }
        return '&sort[]=' + sorting;
      }
      return '';
    },
    bindEnter(e) {
      if (e.keyCode === 13) {
        this.applyFilters();
      }
    },
    loadItems() {
      this.$store.dispatch('withdrawStore/loadItems', {
        params: {
          offset: this.perPage * (this.currentPage - 1),
          limit: this.perPage
        },
        filters: this.filterQuery,
        sort: this.createSortQuery()
      });
    },
    changePage: function (currentPage) {
      this.$store.commit('withdrawStore/setCurrentPage', currentPage);
    },
    applyFilters: function () {
      this.changePage(1);
      this.loadItems();
    },
    resetFilters() {
      this.filterQuery = [];
      this.$root.$emit('resetFilters');
      this.initFilters();
      this.applyFilters();
    },
    initFilters() {
      this.$store.commit('root/initFilters', {
        storeName: 'kyc',
        filterNames: ['status', 'email']
      });
    },
    accept(item) {
      let that = this;
      this.$store.dispatch('withdrawStore/accept', item).then(function () {
        that.loadItems();
      });
    },
    reject(item) {
      let that = this;
      this.$store.dispatch('withdrawStore/reject', item).then(function () {
        that.loadItems();
      });
    },
    paginationReload(data) {
      this.changePage(data.currentPage);
      this.$store.commit('withdrawStore/setPerPage', data.perPage);
      this.loadItems();
    },
  },
  watch: {
    orderColumn: function () {
      this.loadItems();
    },
    orderDirection: function () {
      this.loadItems();
    },
    filterQuery: function () {

    }
  }
}
</script>
